import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faMouse, faGamepad } from '@fortawesome/free-solid-svg-icons';
import './styles/App.css';

function App() {
  return (
    <div className="app">
      <main className="coming-soon">
        <div className="content">
          <h1 className="title">
            <span className="gradient-text">Avunia</span>
          </h1>
          <p className="message">Yakında buralardayız! 👋</p>
          <p className="sub-message">Yazılım ve özel donanım dünyasında yeni bir soluk getiriyoruz.</p>
        </div>
        <div className="background-elements">
          <div className="gradient-sphere"></div>
          <div className="floating-elements">
            <div className="element keyboard">
              <FontAwesomeIcon icon={faKeyboard} />
            </div>
            <div className="element mouse">
              <FontAwesomeIcon icon={faMouse} />
            </div>
            <div className="element controller">
              <FontAwesomeIcon icon={faGamepad} />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
